import React from "react";
import _ from "lodash";
import moment from "moment-strftime";
import { graphql } from "gatsby";

import { Layout } from "../components/index";
import { htmlToReact, withPrefix } from "../utils";

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query($url: String) {
    sitePage(path: { eq: $url }) {
      id
    }
  }
`;

export default class Post extends React.Component {
  render() {
    return (
      <Layout {...this.props}>
        <div className="inner outer">
          <article className="post post-full">
            <header className="post-header inner-sm">
              <h1 className="post-title line-top">
                {_.get(this.props, "pageContext.frontmatter.title", null)}
              </h1>
              {_.get(this.props, "pageContext.frontmatter.subtitle", null) && (
                <div className="post-subtitle">
                  {htmlToReact(
                    _.get(this.props, "pageContext.frontmatter.subtitle", null)
                  )}
                </div>
              )}
            </header>
            {_.get(this.props, "pageContext.frontmatter.image", null) && (
              <div className="post-image">
                <img
                  src={withPrefix(
                    _.get(this.props, "pageContext.frontmatter.image", null)
                  )}
                  alt={_.get(this.props, "pageContext.frontmatter.title", null)}
                />
              </div>
            )}
            <div className="post-content inner-sm">
              {htmlToReact(_.get(this.props, "pageContext.html", null))}
            </div>
            <footer className="post-meta inner-sm">
              <time
                className="published"
                dateTime={moment(
                  _.get(this.props, "pageContext.frontmatter.date", null)
                ).strftime("%Y-%m-%d %H:%M")}
              >
                {moment(
                  _.get(this.props, "pageContext.frontmatter.date", null)
                ).strftime("%B %d, %Y")}
              </time>
            </footer>
          </article>
        </div>
        <p
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <a
            href={`https://twitter.com/intent/tweet?hashtags=FrontendSessions%2C&%2F%3FbuttonLarge%3Don%26buttonRecommendation%3DAlfonzoMillions%26buttonText%3DJust%2520finished%2520reading%2520this%2520dope%2520article%2520by%2520%2540AlfonzoMillions%26buttonType%3DHashtagButton%26buttonUrl%3Dhttp%253A%252F%252Flocalhost%253A8000%252Fposts%252Ftop10-techtest-tips%252F%26query%3D%2523FrontendSessions%26widget%3DButton&ref_src=twsrc%5Etfw&related=AlfonzoMillions&text=Just%20finished%20reading%20this%20dope%20article%20by%20%40AlfonzoMillions&tw_p=tweetbutton&url=${`http://alfiedarko.com${_.get(
              this.props,
              "pageContext.url",
              null
            )}`}`}
            className="twitter-hashtag-button"
            data-size="large"
            data-text={`Just finished reading this dope article by @AlfonzoMillions 👉 ${`https://alfiedarko.com${_.get(
              this.props,
              "pageContext.url",
              null
            )}`}`}
            data-related="AlfonzoMillions"
            data-show-count="false"
          >
            - Share On Twitter 🔥
          </a>
        </p>
      </Layout>
    );
  }
}
